<template>
  <!-- 横屏 -->
  <div class="v-word-index">
    <horizontalScreen>
      <!-- <scaleWrap> -->
        <div class="home">
          <click-audio ref="clickAudio"></click-audio>
          <img @click="backHandler" class="btn-back" src="@/assets/index/btn-back.png"/>
          <div class="btn-book" :style="{backgroundImage: 'url(' + albumNameBg + ')'}"></div>
        </div>
      <!-- </scaleWrap> -->
    </horizontalScreen>
    <div class="word-container">
      <div class="item" @click.stop.prevent="wordClickHandler($event, item, index)" v-for="(item, index) in showWordList" :key="index">
        <div class="left">{{item.gradeName}}</div>
        <div class="right">
          <div v-for="(font, fontIndex) in item.fontNameList" :key="fontIndex">{{font.join(' ')}}</div>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import horizontalScreen from '@/components/horizontal-screen'
import {isEmpty, chunk} from 'lodash'
import ClickAudio from '@/components/clickAudio.vue'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import VueScrollTo from 'vue-scrollto'

export default {
  name: 'book',
  components: {
    horizontalScreen, ClickAudio
  },
  watch: {
    '$route': {
      immediate: true,
      // deep: true,
      handler() {
        this.$nextTick(() => {
            const options = {
              container: '.word-container',
              easing: 'ease-in',
              offset: this.scrollTop,
              force: true,
              cancelable: true
            }
            VueScrollTo.scrollTo(`.word-container`, 0, options)
          
        })
      }
    }
  },
  computed: {
    ...mapGetters(['albumName', 'albumId', 'scrollTop']),
    albumNameBg() {
      return require(`@/assets/word/${this.albumId}.png`)
    }
  },
  mounted() {
    if(document.querySelector('#app section').style.transform === 'rotate(0deg)') {
      // 横屏  word-container
      document.querySelector('.word-container').classList.add('word-container-horizontal')
    }
  },
  data() {
    return {
      type: Number(this.$route.query.type),
      showWordList: []
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    isEmpty,
    ...mapMutations(['SET_SCROLL_TOP', 'SET_SCROLL_INDEX']),
    ...mapActions(['getGradeList']),
    async fetchList() {
      this.$loading.show('数据加载中。。。')
      try {
        const res = await this.getGradeList({
          albumId: this.albumId,
          current: 1,
          pageSize: 20
        })
        console.log('getGradeList', res)
        // this.bookName = res.albumName
        this.showWordList = res.result.map(item => {
          return {
            ...item,
            fontNameList: chunk(item.fontName.replace(/\s+/g,''), 19)
          }
        })
        console.log('showWordList', this.showWordList)
      } finally {
        this.$loading.hide()
      }
    },
    async backHandler() {
      await this.$refs.clickAudio.playAudio()
      this.$router.push({name: 'Book'})
    },
    // 字点击
    wordClickHandler(event, word, index) {
      const scrollTop = document.querySelector('.word-container').scrollTop
      this.SET_SCROLL_TOP(scrollTop)
      this.SET_SCROLL_INDEX(index)
      this.$router.push({name: 'WordCourse', query: {course: word.gradeId, index}})
    }
  }
}
</script>

<style lang="scss" scoped>
.v-word-index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  audio {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: -1;
  }
  .home {
    height: 100%;
    width: 100%;
    background: #fffde6;
    overflow: hidden;
  }
  .btn-book {
    font-weight: bolder;
    color: #72350e;
    font-size: 28px;
    position: fixed;
    width: 184px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
    background-position: center center; 
    background-repeat: no-repeat;  
    background-size: 100%;
  }
  .btn-back {
    color: #2f0000;
    position: fixed;
    width: 65.5px;
    right: 10PX;
    top: 5PX;
  }

  .word-container {
    -webkit-overflow-scrolling: touch; 
    border: solid 1px #e78d02;
    box-sizing: border-box;
    position: absolute;
    overflow-x: scroll;
    height: 70%;
    bottom: 0;
    height: 60vw;
    width: 96vh;
    left: 76%;
    top: 50%;
    transform: translateX(-50%) rotate(90deg);
    transform-origin: top center;
    &-horizontal {
      transform: none;
      transform-origin: left center;
      top: 90px;
      left: 3vw;
      width: 94vw;
      height: 60vh;
    }
    .item { 
      color: #000;
      font-weight: bolder;
      display: flex;
      font-size: 20px;
      align-items: center;
      & + .item {
        border-top: solid 1px #e78d02;
      }
      .left {
        height: 100%;
        // padding: .7% 0;
        width: 120px;
        text-align: center;
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .right {
        border-left: solid 1px #e78d02;
        display: flex;
        align-items: center;
        height: 100%;
        text-align: left;
        // padding: .7% 0 .7% 24px;
        // letter-spacing: 8px;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        > div {
          padding-left: 24px;
          box-sizing: border-box;
          width: 100%;
          line-height: 48px;
          & + div {
            border-top: solid 1px #e78d02;
          }
        }
      }
    }
  }

}
</style>
