<template>
  <!-- 横屏 -->
  <horizontalScreen class="v-word-course">
    <!-- <scaleWrap> -->
    <div class="home" ref="home">
      <img class="logo" src="@/assets/index/img-logo.png" />
      <!-- <img class="game-logo" src="@/assets/index/img-ztrz-new.png"/> -->
      <div class="word-container">
        <div ref="wordPlayBox" class="word-play-box">
          <div class="word-play-box-inner">
            <div class="img" :style="{backgroundImage: 'url(' + currentImg + ')'}"></div>
            <!-- <img :src="currentImg" alt=""> -->
          </div>
        </div>
        <div class="word-list-box">
          <div class="list-box">
            <div class="word" :style="{'visibility': word.name ? 'visible': 'hidden'}" :ref="`word${index}`"
              v-for="(word, index) in currentList" :key="index">
              <!-- <span>{{word.black.img}}</span> -->
              <span @click.stop.prevent="wordClickHandler($event, word, index)">
                <!-- <img :src="word.black.img"/> -->
                <div class="img" :style="{backgroundImage: 'url(' + word.black.img + ')'}"></div>

              </span>
            </div>
          </div>
          <!-- <div class="word-btn-box">
              <img @click="typeHandler(1, $event)" src="@/assets/word/icon-xt.png"/>
              <img @click="typeHandler(2, $event)" src="@/assets/word/icon-xz.png"/>
              <img @click="typeHandler(0, $event)" src="@/assets/word/icon-zt.png"/>
            </div> -->
        </div>
      </div>
      <audio id="myAudio" ref="myAudio" preload></audio>
      <click-audio ref="clickAudio"></click-audio>
      <div class="btn-box" ref="btnBox">
        <div class="btn-box-inner">
          <div style="width: 13%">
            <img @click="backHandler" class="btn-back" src="@/assets/index/btn-back.png" />
          </div>
          <!-- {{showWordObj.gradeName}} -->
          <div style="width: 60%">
            <div class="btn-book" :style="{backgroundImage: 'url(' + courseNameBg + ')'}"></div>
          </div>
          <div style="width: 13%">
            <img class="btn-prev" v-if="pagination.pageIndex !== 1 && !loading" @click="prevClick"
              src="@/assets/word/icon-prev.png" />
          </div>
          <div style="width: 13%">
            <img class="btn-next" v-if="hasNext && !loading" @click="nextClick" src="@/assets/word/icon-next.png" />
          </div>
        </div>
        <div class="btn-box-right">
          <img @click="typeHandler(1, $event)" src="@/assets/word/icon-xtt.png" />
          <img @click="typeHandler(2, $event)" src="@/assets/word/icon-xzz.png" />
          <!-- <img @click="typeHandler(0, $event)" src="@/assets/word/icon-zt.png"/> -->
          ß <!-- <img @click="yxHandler" class="btn-yx" src="@/assets/word/icon-yx.png"/>
            <img @click="ydHandler" class="btn-yd" src="@/assets/word/icon-yd.png"/> -->
        </div>
      </div>
    </div>
    <!-- </scaleWrap> -->
  </horizontalScreen>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
import horizontalScreen from '@/components/horizontal-screen'
import {isEmpty, debounce} from 'lodash'
import ClickAudio from '@/components/clickAudio.vue'
import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'WordCourse',
  components: {
    horizontalScreen, ClickAudio
  },
  computed: {
    ...mapGetters(['albumId', 'scrollIndex']),
    courseNameBg () {
      return require(`@/assets/word/course-${Number(this.$route.query.index) + 1}.png`)
    },
    courseName() {
      const courseObj = {
        1: '第一课',
        2: '第二课',
        3: '第三课',
        4: '第四课',
        5: '第五课',
        6: '第六课',
      }
      return courseObj[Number(this.$route.query.index) + 1]
    },
    currentWord() {
      return this.list[this.index]
    },
    currentImg() {
      if (this.index >= 0) {
        if (this.type == 1) {
          // 1图
          return this.currentList[this.index].color.img
        }
        // 2字
        return this.currentList[this.index].black.img
      }
      return ''
    }
  },
  data() {
    return {
      loading: true,
      showWordObj: {},
      type: 1, // 1图 2字
      index: -1,
      course: Number(this.$route.query.course),
      list: [],
      currentList: [],
      audioList: [],
      hasNext: true,
      pagination: {
        pageIndex: 1,
        pageSize: 9,
        count: 0,
        hasNext: false
      }
    }
  },
  created() {
    this.fetchList()
    // this.handlePage()
  },
  mounted() {
    this.$refs.wordPlayBox.style.height = this.$refs.home.clientHeight - this.$refs.btnBox.clientHeight + 'px'
    // document.documentElement.getBoundingClientRect().width
    console.log('btnBox', document.documentElement.getBoundingClientRect().width, this.$refs.btnBox.clientHeight)
  },
  methods: {
    isEmpty,
    ...mapActions(['getFontList']),
    async fetchList() {
      debounce(async () => {
        this.loading = true
        this.$loading.show('数据加载中。。。')
        try {
          const res = await this.getFontList({
            gradeId: this.course,
            current: this.pagination.pageIndex,
            pageSize: this.pagination.pageSize
          })
          console.log('getGradeList', res)
          this.showWordObj = res
          this.pagination.count = res.total
          this.hasNext = this.pagination.pageIndex*this.pagination.pageSize < this.pagination.count
          this.currentList = res.result.map((item) => {
            return {
              ...item,
              name: item.fontName,
              id: item.fontId,
              color: {
                aduio: item.musicColorUrl,
                img: item.colorUrl
              },
              black: {
                aduio: item.musicFontUrl,
                img: item.fontUrl
              }
            }
          })
          if (this.currentList.length < 9) {
            for(let i=this.currentList.length;i<9;i++) {
              this.currentList.push({
                name: '',
                id: '',
                color: {
                  aduio: '',
                  img: ''
                },
                black: {
                  aduio: '',
                  img: ''
                }
              })
            }
          }
          console.log('currentList', this.currentList)
        } finally {
        this.$loading.hide()
        this.loading = false
        }
      }, 500)()
      
    },
    animeHandler(event) {
      const targetNode = event.target
      anime({
        targets: targetNode,
        scale: [
          { value: 1, duration: 100 },
          { value: 1.1, duration: 100 },
          { value: 1, duration: 100 }
        ],
        complete: () => {
          targetNode.style.transform = 'none'
        }
      });
    },
    async typeHandler(type, event) {
      this.animeHandler(event)
      await this.$refs.clickAudio.playAudio()
      console.log('type', type)
      if (type == 0) {
        this.audioPause()
        console.log('this.type', this.type)
        // 切换字图
        if (this.type == 1) {
          this.type = 2
        } else if (this.type == 2) {
          // 字
          this.type = 1
        }
        console.log('this.type', this.type)
      } else {
        this.type = type
        if (this.index >= 0) {
          this.audioList = []
          console.log(this.currentList[this.index])
          if (this.type === 1) {
            this.audioList = [this.currentList[this.index].color.aduio]
          } else if (this.type === 2) {
            this.audioList = [this.currentList[this.index].black.aduio]
          }
          this.playAudio()
        }
        
      } 
    },
    async ydHandler(event) {
      this.animeHandler(event)
      await this.$refs.clickAudio.playAudio()
      this.$router.push({name: 'ReadCourse', query: {course: this.showWordObj.gradeId}})
    },
    async yxHandler(event) {
      this.animeHandler(event)
      await this.$refs.clickAudio.playAudio()
      let objMap = new Map([
        [1, () => {
          return {
            0: 'Game1',
            1: 'Game2',
            2: 'Game3',
            3: 'Game4',
            4: 'Game5',
            5: 'Game6',
            6: 'Game7',
            7: 'Game8',
            8: 'Game9'
          }
        }],
        [2, () => {
          return {
            0: 'Game10',
            1: 'Game11',
            2: 'Game12',
            3: 'Game13',
            4: 'Game14',
            5: 'Game15',
            6: 'Game16',
            7: 'Game17',
            8: 'Game18',
            9: 'Game19',
            10: 'Game20',
            11: 'Game21'
          }
        }],
        [3, () => {
          return {
            0: 'Game22',
            1: 'Game23',
            2: 'Game24',
            3: 'Game25',
            4: 'Game26',
            5: 'Game27',
            6: 'Game28',
            7: 'Game29',
            8: 'Game30',
            9: 'Game31',
            10: 'Game32',
            11: 'Game33',
            12: 'Game34',
            13: 'Game35',
            14: 'Game36'
          }
        }],
        [4, () => {
          return {
            0: 'Game37',
            1: 'Game38',
            2: 'Game39',
            3: 'Game40',
            4: 'Game41',
            5: 'Game42',
            6: 'Game43',
            7: 'Game44',
            8: 'Game45',
            9: 'Game46',
            10: 'Game47',
            11: 'Game48',
            12: 'Game49',
            13: 'Game50',
            14: 'Game51',
            15: 'Game52',
            16: 'Game53'
          }
        }],
        [5, () => {
          return {
            0: 'Game54',
            1: 'Game55',
            2: 'Game56',
            3: 'Game57',
            4: 'Game58',
            5: 'Game59',
            6: 'Game60',
            7: 'Game61',
            8: 'Game62',
            9: 'Game63',
            10: 'Game64',
            11: 'Game65',
            12: 'Game66',
            13: 'Game67',
            14: 'Game68',
            15: 'Game69',
            16: 'Game70'
          }
        }],
        [6, () => {
          return {
            0: 'Game71',
            1: 'Game72',
            2: 'Game73',
            3: 'Game74',
            4: 'Game75',
            5: 'Game76',
            6: 'Game77',
            7: 'Game78',
            8: 'Game79',
            9: 'Game80',
            10: 'Game81',
            11: 'Game82',
            12: 'Game83',
            13: 'Game84',
            14: 'Game85'
          }
        }]
      ])
      const index = this.scrollIndex
      this.$router.push({name: `${objMap.get(this.albumId)()[index]}`, query: {index: index + 1, id: this.showWordObj.gradeId}})
      // this.$router.push({name: 'GameCourse', query: {index: Number(this.$route.query.index) + 1, id: this.showWordObj.gradeId}})
    },
    async backHandler(event) {
      this.animeHandler(event)
      await this.$refs.clickAudio.playAudio()
      this.$router.back()
      window.wx.miniProgram.navigateBack()
    },
    handlePage() {
      this.pagination.count = this.list.length
      this.pagination.hasNext = this.pagination.pageIndex !== Math.ceil(this.pagination.count/this.pagination.pageSize)
      this.currentList = this.list.slice(
        (this.pagination.pageIndex - 1) * this.pagination.pageSize,
        this.pagination.pageIndex*this.pagination.pageSize
      )
    },
    async prevClick(event) {
      this.index = -1
      const myAudio = document.getElementById('myAudio')
      myAudio.src=null
      this.animeHandler(event)
      // await this.$refs.clickAudio.playAudio()
      // this.fetchList()
      if (this.pagination.pageIndex > 1) {
        this.pagination.pageIndex -= 1
        this.fetchList()
      }
    },
    async nextClick(event) {
      this.index = -1
      const myAudio = document.getElementById('myAudio')
      myAudio.src=null
      this.animeHandler(event)
      // await this.$refs.clickAudio.playAudio()
      // this.fetchList()
      console.log('next', this.pagination.pageIndex * this.pagination.pageSize)
      if (this.pagination.count > this.pagination.pageIndex * this.pagination.pageSize) {
        this.pagination.pageIndex += 1
        this.fetchList()
      }
    },
    playAudio () {
      const myAudio = document.getElementById('myAudio')
      myAudio.src=this.audioList[0]
      myAudio.play()
      let index = 0
      myAudio.onended = () => {
        console.log(index, this.audioList)
        if(index < this.audioList.length - 1){
          myAudio.src=this.audioList[index+1];
          myAudio.play()
          index++;
        } else {
          this.audioList = []
        }
      };
    },
    // 字点击
    wordClickHandler(event, word, index) {
      console.log('word', word, index)
      this.index = index
      let parentNode
      if (event.target.tagName === 'IMG') {
        parentNode = event.target.parentNode.parentNode
      } else if (event.target.tagName === 'SPAN') {
        parentNode = event.target.parentNode
      }
      console.log('event.target', event.target.tagName)
      // const parentNode = this.$refs[`word${index}`]

      anime({
        targets: parentNode,
        scale: [
          { value: 1, duration: 100 },
          { value: 1.1, duration: 100 },
          { value: 1, duration: 100 }
        ],
        complete: () => {
          parentNode.style.transform = 'none'
        }
      });
      this.$refs.clickAudio.playAudio()
      this.audioPause()
    },
    audioPause() {
      this.audioList = []
      this.$refs.myAudio.pause()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-word-course {

  // font-family: 'Alibaba-PuHuiTi-Bold';
  // font-family: 'FZHuPo-M04S';
  .word-container {
    display: flex;
    margin: 0 0 0 36px;
    position: relative;

    .word-play-box {
      width: 390px;
      height: auto;
      // height: 285.7px;
      // height: calc(100vw - 60px);
      background: url(~@/assets/word/bg-window.png) center center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      overflow: hidden;
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .word-play-box-inner {
        width: 85%;
        height: 85%;
        display: flex;
        justify-content: center;
        align-items: center;

        .img {
          width: 100%;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

    }

    .word-list-box {
      flex: 1;

      .list-box {
        width: 100%;
        height: 92%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .word {
          margin-top: -30.6px;
          margin-left: 6px;
          // width: 80px;
          // height: 121.83px;
          width: 30%;
          height: auto;
          background: url(~@/assets/word/bg-board.png) center center;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          position: relative;

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            z-index: 4
          }

          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            z-index: 3
          }

          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9) {
            z-index: 2
          }

          >span {
            // width: 80px;
            // line-height: 70px;
            // height: 70px;
            width: 100%;
            height: 65%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 40px;
            color: #000;
            z-index: 999;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            .img {
              width: 70%;
              height: 70%;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
      }

      .word-btn-box {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-around;

        >img {
          width: 24%;
          height: auto;
        }
      }
    }
  }

  audio {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: -1;
  }

  .home {
    height: 100%;
    width: 100%;
    background: url(~@/assets/word/bg-word.png) center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  // .logo {
  //   width: 28px;
  //   left: 5px;
  //   top: 10PX;
  //   position: fixed;
  // }
  .btn-box {
    padding-top: 10px;
    position: fixed;
    left: 36px;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 5px;

    .btn-box-inner {
      width: 390px;
      flex: 0 1 auto;
      display: flex;
      // justify-content: space-between;
      align-items: center;
    }

    .btn-box-right {
      flex: 1;
      margin-left: 20px;
      // margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      >img {
        width: auto;
        height: 51.5px;
        // width: 30%;
        // height: auto;
      }

      img+img {
        margin-left: 40px;
      }
    }

    .btn-back {
      width: 47.5px;
      height: 47.5px;
    }

    .btn-book {
      margin: 0 auto;
      color: #72350e;
      font-size: 30px;
      width: 173px;
      height: 51.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% 102%;
      border-radius: 20px;
    }

    .btn-prev,
    .btn-next {
      width: 47.5px;
      height: 47.5px;
    }

    .btn-next {
      margin-left: 10px;
    }

    .btn-yx,
    .btn-yd {
      width: 106.5px;
      height: 37.5px;
    }

  }

}</style>
